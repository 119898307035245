// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-me-mdx": () => import("./../../../src/pages/about-me.mdx" /* webpackChunkName: "component---src-pages-about-me-mdx" */),
  "component---src-pages-affiliates-mdx": () => import("./../../../src/pages/affiliates.mdx" /* webpackChunkName: "component---src-pages-affiliates-mdx" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-information-characters-good-goku-mdx": () => import("./../../../src/pages/information/characters/good/goku.mdx" /* webpackChunkName: "component---src-pages-information-characters-good-goku-mdx" */),
  "component---src-pages-information-characters-good-index-mdx": () => import("./../../../src/pages/information/characters/good/index.mdx" /* webpackChunkName: "component---src-pages-information-characters-good-index-mdx" */),
  "component---src-pages-information-characters-good-vegeta-mdx": () => import("./../../../src/pages/information/characters/good/vegeta.mdx" /* webpackChunkName: "component---src-pages-information-characters-good-vegeta-mdx" */),
  "component---src-pages-information-characters-index-mdx": () => import("./../../../src/pages/information/characters/index.mdx" /* webpackChunkName: "component---src-pages-information-characters-index-mdx" */),
  "component---src-pages-information-fan-art-mdx": () => import("./../../../src/pages/information/fan-art.mdx" /* webpackChunkName: "component---src-pages-information-fan-art-mdx" */),
  "component---src-pages-information-gifs-fusion-mdx": () => import("./../../../src/pages/information/gifs/fusion.mdx" /* webpackChunkName: "component---src-pages-information-gifs-fusion-mdx" */),
  "component---src-pages-information-gifs-goku-vs-cell-mdx": () => import("./../../../src/pages/information/gifs/goku-vs-cell.mdx" /* webpackChunkName: "component---src-pages-information-gifs-goku-vs-cell-mdx" */),
  "component---src-pages-information-gifs-goten-and-trunks-mdx": () => import("./../../../src/pages/information/gifs/goten-and-trunks.mdx" /* webpackChunkName: "component---src-pages-information-gifs-goten-and-trunks-mdx" */),
  "component---src-pages-information-gifs-gotenks-mdx": () => import("./../../../src/pages/information/gifs/gotenks.mdx" /* webpackChunkName: "component---src-pages-information-gifs-gotenks-mdx" */),
  "component---src-pages-information-gifs-index-mdx": () => import("./../../../src/pages/information/gifs/index.mdx" /* webpackChunkName: "component---src-pages-information-gifs-index-mdx" */),
  "component---src-pages-information-gifs-special-beam-cannon-mdx": () => import("./../../../src/pages/information/gifs/special-beam-cannon.mdx" /* webpackChunkName: "component---src-pages-information-gifs-special-beam-cannon-mdx" */),
  "component---src-pages-information-gifs-trunks-vs-cell-mdx": () => import("./../../../src/pages/information/gifs/trunks-vs-cell.mdx" /* webpackChunkName: "component---src-pages-information-gifs-trunks-vs-cell-mdx" */),
  "component---src-pages-information-index-mdx": () => import("./../../../src/pages/information/index.mdx" /* webpackChunkName: "component---src-pages-information-index-mdx" */),
  "component---src-pages-information-sagas-mdx": () => import("./../../../src/pages/information/sagas.mdx" /* webpackChunkName: "component---src-pages-information-sagas-mdx" */),
  "component---src-pages-java-chat-mdx": () => import("./../../../src/pages/java-chat.mdx" /* webpackChunkName: "component---src-pages-java-chat-mdx" */),
  "component---src-pages-rm-2-k-mdx": () => import("./../../../src/pages/rm2k.mdx" /* webpackChunkName: "component---src-pages-rm-2-k-mdx" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */)
}


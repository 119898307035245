import SAVE0000 from "../../../../src/images/SAVE0000.jpeg";
import SAVE0001 from "../../../../src/images/SAVE0001.jpeg";
import SAVE0002 from "../../../../src/images/SAVE0002.jpeg";
import SAVE0003 from "../../../../src/images/SAVE0003.jpeg";
import * as React from 'react';
export default {
  SAVE0000,
  SAVE0001,
  SAVE0002,
  SAVE0003,
  React
};